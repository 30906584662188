import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import Home from "./pages/Home";
import Meals from "./pages/Meals";
import Users from "./pages/Users";
import NavBar from "./components/Navbar";
import Login from "./components/Login";
import Register from "./components/RegisterUser";
import "./App.css";
import github from './components/github.png'

function App() {
  const isLoggedIn = !!localStorage.getItem("token");

  return (
    <Router>
      <div className="App">
        {isLoggedIn ? (
          <>
            <NavBar className="layout" />
            <Routes>
              <Route path="/" element={<Meals />} />
              <Route path="/newmeal" element={<Home />} />
              <Route path="/users/:action" element={<Users />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </div>
      <nav className="footer">
        <div className="footerAuthors">
      
          <div className="footernames">
          <h4>Original Authors</h4>
            <div className="names">
           <Link to={'https://github.com/Kyle2617'} >Kyle <img src={github}/></Link>
            <Link to={'https://github.com/ElenaChanou'}>Eleni Chanou <img src={github}/></Link>
            <Link to={'https://github.com/aggelos-galanopoulos'}> Angelos Galanopoulos <img src={github}/></Link>
            </div>
          
          </div>
          <div className="footernames">
          <h4>Contributors</h4>
            <div className="names">
            <Link to={'https://github.com/AchmetCh'}>Achmet Chasankil <img src={github}/></Link>
            <Link to={'https://github.com/dumancelil'}>Celil Duman <img src={github}/></Link>
            <Link to={'https://github.com/krishotaj'}>Kristiana Hotaj <img src={github}/></Link>
            </div>
          
          </div>
      
        </div>
       
      </nav>
    </Router>
  );
}

export default App;

// const App = () => {
//   const isLoggedIn = !!localStorage.getItem('token');
//   return (
//     <Router>
//       <NavBar />
//       <div className="main-content">
//       <Routes>
//           <Route path="/" element={<Login />} />
//           <Route path="/meals" element={<Meals />} />
//           <Route path="/users/:action" element={<Users />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// };

// export default App;
