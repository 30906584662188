import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NavBar.css';
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {jwtDecode} from "jwt-decode";

const ToastSuccessful = () => toast("Logout successfuly");
const NavBar = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const username = decodedToken.username 
  console.log(username);
  const navigate = useNavigate(); 
  const handleLogout = () => {
    localStorage.removeItem('token')
    setTimeout(() => window.location.href = `/`,1000); 
    ToastSuccessful()
}

  return (
    <nav className="navbar">
      <div className="navbar-brand" onClick={() => navigate('/')}>Diet Tracker</div>
      <div>Welcome <span className='username'>{username}</span></div>
      <div className="navbar-links">
        <Link to="/">Meals</Link>
        <Link to="/newmeal">Add Meal</Link>
      </div>
      <div>
      <button className='LogoutBtn' onClick={handleLogout}>Logout</button>
      </div>
      <ToastContainer position="top-center" autoClose={2000} theme="colored"/>
    </nav>
  );
};

export default NavBar;

