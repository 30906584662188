import axios from 'axios';
// const API_URL = 'http://localhost:5000/users';
const API_URL = 'https://diettracker-zx2o.onrender.com/users';

export const registerUser = async (user) => {
  return await axios.post(`${API_URL}/register`, user);
};

export const loginUser = async (user) => {
  return await axios.post(`${API_URL}/login`, user);
};
