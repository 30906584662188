import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import './registeruser.css'

const RegisterUser = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const apiUrl = "https://diettracker-zx2o.onrender.com/users/register";
  const ToastSuccessful = () => toast("Registered Successful!");
  const ToastFailed = () => toast("Password and Confirmation not match");
  const ToastALreadyExist = () => toast("Users already exist try to login");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      ToastFailed();
      return;
    }
    const newUser = { username, email, password };
    try {
      const res = await axios.post(apiUrl, newUser);
      ToastSuccessful();
      setTimeout(() => navigate("/"), 2000);
    } catch (err) {
      ToastALreadyExist();
    }
  };

  return (
    <div className="container">
      <nav className="navbar">
      <div className="navbar-brand" onClick={() => navigate('/')}>Diet Tracker</div>
      </nav>
      <h1>RegisterUser</h1>
      <form className="RegisterForm" onSubmit={handleRegister}>
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button className="RegisterBtn" type="submit">Register</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default RegisterUser;
