import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './login.css'

const ToastSuccessful = () => toast("Login Successful!");
const ToastFailed = () => toast("Login Failed! Please try again.");

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const apiUrl = "https://diettracker-zx2o.onrender.com";

  const handleSubmit = async (e) => {
    e.preventDefault();
    const credentials = { email, password };
    try {
      const response = await axios.post(`${apiUrl}/users/login`, credentials);
      console.log(response.data.token);

      localStorage.setItem("token", response.data.token);
      ToastSuccessful();
      setTimeout(() => (window.location.href = "/"), 1000);
    } catch (error) {
      ToastFailed();
      console.log(error);
      console.log("test");
    }
  };
  const handleRegister = () => {
    navigate("/register");
  }

  return (
    <div className="container">
               <nav className="navbar">
      <div className="navbar-brand" onClick={() => navigate('/')}>Diet Tracker</div>
      </nav>
      <main className="imgForm">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div>
              <label>email</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit">Login</button>
          </form>
          <button onClick={handleRegister}>Register</button>
        </div>
      </main>
      <ToastContainer theme="dark" />
    </div>
  );
};

export default Login;
