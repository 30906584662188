import axios from 'axios';
import {jwtDecode} from "jwt-decode";

// const API_URL = 'http://localhost:5000/meals';
const API_URL = 'https://diettracker-zx2o.onrender.com/meals';

export const getMeals = async () => {
  return await axios.get(API_URL);
};

export const addMeal = async (meal) => {
  return await axios.post(`${API_URL}/user`, meal);
};

export const updateMeal = async (id, meal) => {
  return await axios.put(`${API_URL}/user/${id}`, meal);
};

export const deleteMeal = async (id) => {
  return await axios.delete(`${API_URL}/user/${id}`);
};
