import React, { useState } from "react";
import { addMeal } from "../services/mealService";
import "./MealForm.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";

const ToastSuccessful = () => toast("Meal added Successful!");
const MealForm = ({ refreshMeals, existingMeal, handleUpdate }) => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const userId = decodedToken.id;
  console.log(userId);
  const [meal, setMeal] = useState(
    existingMeal || {
      name: "",
      date: "",
      mealType: "",
      calories: "",
      user: userId,
    }
  );
  const navigate = useNavigate();

  const handleChange = (e) => {
    setMeal({ ...meal, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (existingMeal) {
      await handleUpdate(meal);
    } else {
      await addMeal(meal);
      ToastSuccessful();
      refreshMeals();
      setMeal({ name: "", date: "", mealType: "", calories: "", user: userId });
    }
  };



  return (
    <div>
      <form className="meal-form" onSubmit={handleSubmit}>
        <h3>{existingMeal ? "Edit Meal" : "Add Meal"}</h3>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={meal.name}
          onChange={handleChange}
          required
        />
        <input
          type="date"
          name="date"
          value={meal.date.split("T")[0]}
          onChange={handleChange}
          required
        />
        <select
          name="mealType"
          value={meal.mealType}
          onChange={handleChange}
          required
        >
          <option value="" disabled>
            Select Meal Type
          </option>
          <option value="Breakfast">Breakfast</option>
          <option value="Snack">Snack</option>
          <option value="Lunch">Lunch</option>
          <option value="Dinner">Dinner</option>
        </select>
        <input
          type="number"
          name="calories"
          placeholder="Calories"
          value={meal.calories}
          onChange={handleChange}
          required
        />
        <div className="UpdCnl">
       <button type="submit">
    {existingMeal ? "Update Meal" : "Add Meal"}
  </button>
  {existingMeal && (
    <button type="button" onClick={() =>  window.location.reload()}>
      Cancel
    </button>
  )}
  </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default MealForm;
